<template>
  <b-card title="View Item">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group
              id="model"
              label="Model"
              label-for="model"
              description="Item Model"
            >
              <b-form-input
                id="input-model"
                v-model="form.itemModel"
                type="text"
                placeholder="Enter item model"
                required
                disabled
              ></b-form-input>
          </b-form-group>

          <b-form-group
              id="description"
              label="Description"
              label-for="description"
              description="Description"
            >
              <b-form-input
                id="input-description"
                v-model="form.description"
                type="text"
                placeholder="Enter description"
                required
                disabled
              ></b-form-input>
          </b-form-group>
          <!--
          <b-form-group
              label="Point Issued"
              description="Point Issued"
            >
              <b-form-input
                id="input-price"
                v-model="form.pointIssued"
                type="text"
                placeholder="Enter Point"
                disabled
              ></b-form-input>
          </b-form-group>

          <b-form-group
              label="Sbu"
              description="Sbu"
            >
              <b-form-input
                id="input-price"
                v-model="form.sbu"
                type="text"
                placeholder="Enter Sbu"
                disabled
              ></b-form-input>
          </b-form-group>

          -->
          <b-form-group
              label="Product Hierarchy"
              description="Product Hierarchy"
            >
              <b-form-input
                id="input-price"
                v-model="form.productHierarchy"
                type="text"
                placeholder="Enter Hierarchy"
                disabled
              ></b-form-input>
          </b-form-group>

          <b-form-group
              id="status"
              label="Status"
              label-for="status"
              description="Status"
            >
              <b-form-input
                id="input-price"
                v-model="form.status"
                type="text"
                placeholder="Enter Hierarchy"
                disabled
              ></b-form-input>
          </b-form-group>

        </b-form>
      </b-col>
      <b-col cols="12">
          <b-table
            :items="channel"
            :fields="fields"
            responsive
            show-empty
          >
            <template #cell(channel)="row">
              {{row.item.channel.name}}
            </template>
          </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
  data() {
    return {
     form: {
      category: undefined,
      pcode: undefined,
     },
     channel:[],
     categories: [],
     pcodes: [],
      fields: [
        { key: 'channel', label: 'Distribution Channel', sortable: true },
        { key: 'price', label: 'Price', sortable: true},
      ],
     itemStatusOptions: [
      { value: '', text: 'Active' },
      { value: 'B', text: 'Inactive' }
     ]
    }
  },
  mounted() {
    this.getItemDetail();
    this.getChannel();
  },
  methods: {
    ...mapActions({

    }),
    getItemDetail() {
      axios.get(`sales-and-purchase/drcc/item/detail/${this.$route.params.id}`).then((response) => {
        let item = response.data.data;
        item.status = item.status === '' ? 'Active' : 'Inactive';
        this.form = item;
      }).catch((e) => {
        console.log(e)
      })
    },
    getChannel() {
      axios.get('sales-and-purchase/drcc/multi-price').then((response) => {
        var temporary = response.data.data;
        this.channel = temporary.filter((x)=>{return x.item._id===this.$route.params.id})
        this.channel.map(x=>{
          return x.price = x.price.$numberDecimal
        })
      }).catch((e) => {
        console.log(e)
      })
    },
  }
}
</script>
